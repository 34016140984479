<template>
  <div class="update-channel-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(updateChannel)">
        <b-form-group>

          <!-- Set Name -->
          <b-input-group class="mb-1 d-flex flex-column mt-1">
            <div>
              <label>New Name</label>
              <validation-provider
                v-slot="validationContext"
                name="channel name"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="channel.name"
                  placeholder="Enter a new channel name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <!-- Set Image URL -->
          <b-input-group class="mb-2 d-flex flex-column ">


            <!-- Tag Select -->
            <div class="mb-1">
              <label>Add Tags (start typing to search)</label>
              <v-select
                v-model="selectedTags"
                :options="tags"
                :reduce="tag => tag"
                label="name"
                push-tags
                multiple
                @search="debounceGetTags"
              >
                <template v-slot:option="option">
                  <span>
                    {{ option.name }}
                  </span>
                </template>
                <template v-slot:no-options>
                  <span>
                    No tags found. Try adjusting your search.
                  </span>
                </template>
              </v-select>
            </div>

          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse mt-3">
            <b-btn variant="primary" type="submit" :disabled="invalid">Update Channel</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('refresh')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import adminChannelService from '@/services/AdminChannelService';
import helperService from '@/services/HelperService';
import kioskTagService from '@/services/KioskTagService';
import channelService from '@/services/YoutubeChannelService';
import debounce from 'lodash/debounce';
import { url } from '@core/utils/validations/validations';
import vSelect from "vue-select";

export default {
  name: 'UpdateChannelModal',
  components: {
    vSelect,
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      channel: {
        name: '',
        image_url: '',
        tags: [],
      },
      tags: [],
      selectedTags: [],
      debounceGetTags: () => {},
      filters: {
        page: 0,
        page_size: 12,
        search: '',
      },
    }
  },
  computed: {
    url() {
      return url
    },
  },
  mounted() {
    this.getChannel()
    this.getTags()
    this.debounceGetTags = debounce(this.getMoreTags, 300)
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateChannel() {
      try {
        const tags = this.selectedTags.map(tag => ({ ...tag }))
        const dto = {
          name: this.channel.name,
          image_url: this.channel.image_url,
          tags: tags.map(tag => tag.id),
        }
        await adminChannelService.updateChannel(this.id, dto)
        helperService.showNotfySuccess(this.$toast, 'The channel has been updated successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while updating the channel. Please try again.')
      } finally {
        this.$emit('refresh');
        this.clearFilters()
      }
    },
    async getChannel() {
      try {
        const { data } = await channelService.getChannel(this.id)
        this.channel = data
        this.selectedTags = data.tags
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while fetching the channel. Please refresh and try again.')
        this.$emit('refresh');
      }
    },
    async getTags() {
      try {
        const { data } = await kioskTagService.listTags(this.filters);
        this.tags = data.data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while fetching the channel tags. Please refresh and try again.')
        this.$emit('refresh');
      }
    },
    async getMoreTags(search, loading) {
      loading(true)
      this.filters.search = search
      await this.getTags()
      loading(false)
    },
    clearFilters() {
      this.filters = {
        page: 0,
          page_size: 12,
          search: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

</style>
