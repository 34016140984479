import axios from '@axios';

const baseUrl = '/admin/youtube-channels';

export default {
  createChannel(dto) {
    return axios.post(`${baseUrl}`, dto);
  },
  updateChannel(id, dto) {
    return axios.put(`${baseUrl}/${id}`, dto);
  },
  deleteChannel(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  async uploadChannelImage(id, image) {
    const formData = new FormData()
    formData.append('file', image)
    const { data } = await axios.put(`${baseUrl}/${id}/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data
  },
};
