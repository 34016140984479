<template>
  <div class="create-channel-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(createChannel)">
        <b-form-group>
          <!-- Set Name -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="channel name"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="channel.name"
                  placeholder="Enter the channel name..."
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <!-- Set YT channel ID -->
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label>Purple Play Channel ID</label>
              <validation-provider
                v-slot="validationContext"
                name="Purple Play channel ID"
                :rules="{ required:true }"
              >
                <b-form-input
                  v-model="channel.channel_id"
                  placeholder="Eg. TCsJoURrOoezykLs9SqgamLA"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse">
            <b-btn variant="primary" type="submit" :disabled="invalid || submitting">Create Channel</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import adminChannelService from '@/services/AdminChannelService';
import { url } from '@core/utils/validations/validations';

export default {
  name: 'CreateChannel',
  components: {
  },
  data() {
    return {
      submitting: false,
      channel: {
        name: '',
        channel_id: '',
        image_url: '',
      },
    }
  },
  computed: {
    url() {
      return url
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createChannel() {
      this.submitting = true
      try {
        await adminChannelService.createChannel(this.channel)
        helperService.showNotfySuccess(this.$toast, 'The channel has been created successfully.')
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while creating the channel. Please refresh and try again.')
      } finally {
        this.submitting = false
        this.$emit('refresh')
      }
    },
  },
}
</script>

<style scoped>

</style>
