<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="kiosk-channels-page">
    <b-card title="Manage Channels">
      <b-btn
         variant="success"
         class=" float-right" style="margin-top: -10px; margin-right: 28px;"
         @click="$bvModal.show('modal-create-channel');"
      >
        Add New Channel
      </b-btn>
      <b-card-text>This page displays the Purple Play channel's available in the kiosk application.  Manage the channels by adding, removing or updating the channel information.  </b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
      <b-row class="d-flex justify-content-end mr-1">

        <!-- Search -->
        <b-col cols="12" md="6">
          <div>
            <b-input-group>
              <b-form-input
                @keydown.enter="getMoreChannels(1)"
                v-model="filters.search"
                class="d-inline-block"
                placeholder="Search by channel name..."
              />
              <b-input-group-append>
                <b-button @click="getMoreChannels(1)">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>

      </b-row>
      </div>

      <!-- Table -->
      <section>
        <b-table
          class="data-table"
          hover
          :items="channels"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(image_url)="data">
            <div>
              <b-avatar
                size="40"
                variant="light-primary"
                :src="data.item.image_url"
                class="badge-minimal"
              />
              <b-button
                class="ml-1"
                variant="primary"
                size="sm"
                @click.stop="showChannelModal(data.item, 'modal-upload-image')"
              >
                Edit
              </b-button>
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(channel_id)="data">
            <a :href="data.item.channel_id | formatYoutubeChannelUrl" class="font-weight-bold d-block text-nowrap" target="_blank">
            {{ data.item.channel_id }}
            </a>
          </template>

          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showChannelModal(data.item, 'modal-update-channel')">
                Update
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn"
                                      @click="showChannelModal(data.item, 'modal-delete-channel')">
                Delete
              </b-dropdown-item-button>
            </b-dropdown>
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreChannels" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p>No channels found.</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-channel" title="Add a new channel"  hide-footer>
      <CreateChannelModal @close="closeModals" @refresh="refreshChannels"/>
    </b-modal>

    <b-modal id="modal-update-channel" title="Update a channel"  hide-footer no-close-on-backdrop>
      <UpdateChannelModal
        v-if="selectedChannel"
        :id="selectedChannel.id"
        @close="closeModals"
        @refresh="refreshChannels"/>
    </b-modal>

    <b-modal id="modal-delete-channel" title="Delete a channel" hide-footer>
      <delete-modal
        v-if="selectedChannel"
        @close="closeModals()"
        @delete="deleteChannel(selectedChannel.id)"
        :subtitle="selectedChannel.name"
        title="Are you sure you wish to delete this channel?"></delete-modal>
    </b-modal>

    <b-modal id="modal-upload-image" title="Upload a channel image" hide-footer>
      <image-upload-modal
        @upload="updateChannelImage"
        @close="$bvModal.hide('modal-upload-image')"
      ></image-upload-modal>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import CreateChannelModal from '@/views/kiosk/viewKioskChannels/CreateChannelModal.vue';
import UpdateChannelModal from '@/views/kiosk/viewKioskChannels/UpdateChannelModal.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import helperService from '@/services/HelperService';
import { BAvatar } from 'bootstrap-vue';
import channelService from '../../services/YoutubeChannelService';
import adminChannelService from '../../services/AdminChannelService';
import AdminChannelService from "../../services/AdminChannelService";
import ImageUploadModal from "@/views/kiosk/viewKioskChannels/ImageUploadModal.vue";

export default {
  name: 'KioskChannels',
  components: {
    BAvatar,
    DeleteModal,
    CreateChannelModal,
    UpdateChannelModal,
    ImageUploadModal,
  },
  data() {
    return {
      selectedChannel: null,
      headers: [
        {
          key: 'image_url',
          label: 'Thumbnail',
        },
        {
          key: 'name',
          label: 'Channel Name',
        },
        {
          key: 'channel_id',
          label: 'Channel ID',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      channels: [],
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      currentPage: 1,
      total_count: 0,
    };
  },
  mounted() {
    this.getChannels();
  },
  methods: {
    refreshChannels() {
      this.closeModals();
      this.getMoreChannels(1);
    },
    getMoreChannels(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getChannels();
    },
    async getChannels() {
      try {
        const { data } = await channelService.getChannels(this.filters)
        this.channels = data.data;
        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not fetch channels, please refresh and try again')
      }
    },
    async deleteChannel(id) {
      try {
        await adminChannelService.deleteChannel(id)
        helperService.showNotfySuccess(this.$toast, `${this.selectedChannel.name} has been successfully deleted.`)
        this.refreshChannels()
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while deleting the channel. Please try again.')
      }
    },
    async updateChannelImage(image) {
      try {
        if (!this.selectedChannel) return
        const res = await AdminChannelService.uploadChannelImage(this.selectedChannel.id, image)
        helperService.showNotfySuccess(this.$toast, 'Image uploaded')
        this.selectedChannel.image_url = res.image_url
        this.$bvModal.hide('modal-upload-image')
      } catch (err) {
        let errorText = 'Could not upload channel image, please refresh and try again'
        if (err.response && err.response.data.error) {
          errorText = err.response.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };
      this.refreshChannels()
    },
    closeModals() {
      this.$bvModal.hide('modal-create-channel');
      this.$bvModal.hide('modal-update-channel');
      this.$bvModal.hide('modal-delete-channel');
    },
    showChannelModal(channel, modalName) {
      this.selectedChannel = channel
      this.$bvModal.show(modalName);
    },
  },
};
</script>
